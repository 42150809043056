import React from "react";

function WhatsappIcon({color}) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <path d="M19.077 4.928A9.927 9.927 0 0012.011 2c-5.506 0-9.987 4.479-9.989 9.985a9.962 9.962 0 001.333 4.992L2 22l5.233-1.237a9.98 9.98 0 004.773 1.216h.004c5.505 0 9.986-4.48 9.989-9.985a9.922 9.922 0 00-2.922-7.066zm-7.07 15.051a7.998 7.998 0 01-3.816-.972l-.673-.367-.745.176-1.968.465.481-1.784.216-.802-.415-.719a7.974 7.974 0 01-1.065-3.991C4.024 7.583 7.607 4 12.01 4a7.949 7.949 0 015.652 2.341 7.931 7.931 0 012.337 5.651c-.002 4.404-3.586 7.987-7.992 7.987z"></path>
        <path d="M16.898 15.554c-.208.583-1.227 1.145-1.685 1.186-.458.042-.887.207-2.995-.624-2.537-1-4.139-3.601-4.263-3.767-.125-.167-1.019-1.353-1.019-2.581s.645-1.832.874-2.081a.916.916 0 01.666-.312c.166 0 .333 0 .478.006.178.007.375.016.562.431.222.494.707 1.728.769 1.853s.104.271.021.437-.125.27-.249.416c-.125.146-.262.325-.374.437-.125.124-.255.26-.11.509.146.25.646 1.067 1.388 1.728.954.85 1.757 1.113 2.007 1.239.25.125.395.104.541-.063.146-.166.624-.728.79-.978s.333-.208.562-.125 1.456.687 1.705.812c.25.125.416.187.478.291.062.103.062.603-.146 1.186z"></path>
      </g>
    </svg>
  );
}

export default WhatsappIcon;
