import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { indexCollectionRef } from "../../Components/firebase/firebase-config";
import TagIcon from "@mui/icons-material/Tag";
import {Helmet} from "react-helmet";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  CircularProgress,
  ThemeProvider,
  Grid,
  Box
} from "@mui/material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    chip0: {
      main: "#32c95b",
      contrastText: "#fff",
    },
    chip1: {
      main: "#eb3169",
      contrastText: "#fff",
    },
    chip2: {
      main: "#31b9eb",
      contrastText: "#fff",
    },
    chip3: {
      main: "#fcd03d",
      contrastText: "#fff",
    },
  },
});

const SearchPage = () => {
  const params = useParams();

  const [searchResults, setSearchResults] = useState([]);
  const [searchJson, setSearchJson] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getIndex = async () => {
    const docRef = doc(indexCollectionRef, "blog-index");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return JSON.parse(docSnap.data().index);
    } else {
      return null;
    }
  };

  useEffect(() => {
    // Fetch data from Firebase
    getIndex().then((data) => {
      setSearchJson(data);
      handleSearch(data);
      setIsLoading(false);
    });
  }, []);

  const handleSearch = (data) => {
    // Create new Fuse instance
    const options = {
      keys: [
        { name: "body", weight: 0.17 },
        { name: "title", weight: 0.3 },
        { name: "author_name", weight: 0.01 },
        { name: "author_uid", weight: 0.01 },
        { name: "url", weight: 0.01 },
        { name: "tags", weight: 0.5 },
      ],
      includeScore: true,
      limit: 10,
    };
    var fuse = null;
    if (searchJson.length > 0) {
      fuse = new Fuse(searchJson, options);
    } else {
      fuse = new Fuse(data, options);
    }
    // Search for results
    const results = fuse.search(params.key);
    results.sort(function (a, b) {
      return b.score - a.score;
    });
    setSearchResults(results);
  };

  return (
    <div>
       <Helmet>
          <title>Search Results for - Blue Labs</title>
          <meta name="title" content="Search Results for - Blue Labs" />
          <meta name="description" content="Search Page for blue labs Search and learn the from the best tutorials eductional website filled with uppaid cources best study materials" />
      </Helmet>
      {isLoading ? (
        <div className="isLoadingDiv">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {searchResults.map((item) => (
            <Card
              sx={{ margin: 4 }}
              onClick={() => {
                window.location.assign(item["item"]["url"]);
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  By: {item["item"]["author_name"]}
                </Typography>
                <Typography variant="h5" component="div">
                  {item["item"]["title"].charAt(0).toUpperCase() +
                    item["item"]["title"].slice(1)}
                </Typography>
                <ThemeProvider theme={theme}>
                  <Box sx={{ marginLeft: 1, marginTop: 2 }}>
                    <Grid
                      container
                      spacing={{ xs: 1, md: 3 }}
                      columns={{ xs: 4, sm: 9, md: 20 }}
                    >
                      {item["item"]["tags"].map((tag, index) => (
                        <Chip
                          sx={{ marginLeft: 1, marginTop: 2 }}
                          icon={<TagIcon />}
                          label={tag}
                          color={`chip${index}`}
                          variant="outlined"
                        />
                      ))}
                    </Grid>
                  </Box>
                  
                </ThemeProvider>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
