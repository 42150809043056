import * as React from "react";

const BlueLabsICColored = (props) => (
  <svg
    width={50}
    height={64}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M37.696 31.186c2.95 4.461 6.64 10.045 10.063 18.592.16.4.337.811.519 1.232C50.718 56.682 53.868 64 24.89 64S-.94 56.682 1.502 51.01c.181-.42.359-.833.519-1.232 3.422-8.547 7.113-14.13 10.063-18.592 3.317-5.017 5.695-8.615 5.695-13.408V7.11H16a1.778 1.778 0 0 1-1.778-1.778V1.778A1.778 1.778 0 0 1 16.001 0h17.778a1.778 1.778 0 0 1 1.777 1.778v3.555a1.778 1.778 0 0 1-1.777 1.778H32v10.667c0 4.793 2.378 8.39 5.695 13.408Z"
      fill="#000B76"
    />
    <path
      d="m23.04 39.513.343.328c.765.734 1.554 1.49 2.416 2.097 2.166 1.523 4.255 2.99 6.21 2.99 3.057 0 4.068-1.53 4.667-2.435l.05-.076c-.252-.45-.515-.905-.791-1.365-4.32-7.198-8.743-4.445-12.895-1.54Z"
      fill="url(#a)"
    />
    <path
      d="M25.19 57.898c9.657 0 14.625-.85 17.12-1.597.886-.266 1.23-1.29.918-2.232a54.59 54.59 0 0 0-5.017-11.097c-1.386-2.332-.932-2.012-1.82-.67-.598.907-1.61 2.437-4.665 2.437-1.956 0-4.045-1.468-6.212-2.991-4.28-3.01-6.76-9.748-13.344 1.224-2.759 4.597-4.235 8.714-5.017 11.097-.313.942.032 1.97.918 2.232 2.496.747 7.463 1.597 17.12 1.597Z"
      fill="url(#b)"
    />
    <circle cx={29.623} cy={31.634} r={1.097} fill="#71CFD5" />
    <circle cx={21.577} cy={26.514} r={1.097} fill="#71CFD5" />
    <circle cx={23.04} cy={31.634} r={1.829} fill="#71CFD4" />
    <circle cx={26.697} cy={23.589} r={1.829} fill="#71CFD4" />
    <defs>
      <linearGradient
        id="a"
        x1={36.48}
        y1={40.96}
        x2={23.04}
        y2={39.68}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0019FF" />
        <stop offset={1} stopColor="#081BD2" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={9.92}
        y1={48.32}
        x2={41.92}
        y2={48.96}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008BFF" />
        <stop offset={1} stopColor="#0094FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default BlueLabsICColored;
