import MarkdownHandler from "../../Components/MarkdownHandler/MarkdownHandler";
import React, { useState } from 'react';

import policy from '../privacypolicy-page/policy.md';
import { Card, CardContent } from "@mui/material";





export default function PrivacyPolicyPage(){

    const [privacyPolicy, setprivacyPolicy] = useState("");
    
    fetch(policy)
    .then(r => r.text())
    .then(text => {
        setprivacyPolicy(text)
    });

    return(

        <Card>
            <CardContent>
                <MarkdownHandler input={privacyPolicy}/>
            </CardContent>
        </Card>


    )

}