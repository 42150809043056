import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WithEditor from "./Pages/editor-page/WithEditor";
import PageNotFound from "./Pages/error-pages/404Error/404";
import ProfilePage from "./Pages/profile-page/ProfilePage";
import HomePage from "./Pages/home-page/HomePage";
import BlogAppBar from "./Components/AppBar/Appbar";
import Store from "./Pages/store-page/store-page";
import BlogPage from "./Pages/blog-page/BlogPage";
import SearchPage from "./Pages/search-page/SearchPage";
import IndexPage from "./Pages/indexer-page/indexer";
import PrivacyPolicyPage from "./Pages/privacypolicy-page/privacy";
import PortfolioPage from "./Pages/portfolio-page/portfolio";
import { Helmet } from "react-helmet";



function App() {
  return (
    <div className="App">
       <Helmet>
              <meta name="google-adsense-account" content="ca-pub-5132263101609337" />
      </Helmet>
      <div>
        <BrowserRouter>
          <BlogAppBar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/editor" element={<WithEditor />} />
            <Route path="/:author/:bloguri" element={<BlogPage />} />
            <Route path="/profile/:uid" element={<ProfilePage />} />
            <Route path="/store" element={<Store />} />
            <Route path="/search/:key" element={<SearchPage />} />
            <Route path="/indexer" element={<IndexPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />


            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
