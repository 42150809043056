import React from "react";

function IC_MAILTO({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1.1"
      fill={color}
      viewBox="0 0 6.35 6.35"
    >
      <g stroke={color}>
        <path
          fill="none"
          strokeOpacity="1"
          strokeWidth="0.252"
          d="M2.414 -0.805H5.162000000000001V1.943H2.414z"
          transform="rotate(45)"
        ></path>
        <path
          fill="none"
          strokeOpacity="1"
          strokeWidth="0.252"
          d="M3.735 -2.103H6.4830000000000005V0.645H3.735z"
          transform="rotate(45)"
        ></path>
        <rect
          width="1.29"
          height="1.38"
          x="3.816"
          y="-0.7"
          fill={color}
          fillOpacity="1"
          strokeOpacity="0.754"
          strokeWidth="0.199"
          ry="0"
          transform="scale(1.00905 .99086) rotate(45)"
        ></rect>
      </g>
    </svg>
  );
}

export default IC_MAILTO;
