import React from "react";

function IC_WARNING({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={color}
      viewBox="0 -960 960 960"
    >
      <path d="M40-120l440-760 440 760H40zm138-80h604L480-720 178-200zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240zm-40-120h80v-200h-80v200zm40-100z"></path>
    </svg>
  );
}

export default IC_WARNING;
