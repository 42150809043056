import { Fab } from "@mui/material";
import { FacebookRounded } from "@mui/icons-material";
import {
  AiFillGithub,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BsStackOverflow } from "react-icons/bs";

export default function ProfileFab({ profiles }) {
  if (profiles && profiles.length > 0) {
    profiles = profiles.sort();
  }

  return profiles.map((url) => {
    if (url.includes("facebook")) {
      return (
        <a href={url} target="_blank">
          <div className="linkIcon">
            <Fab size="small" color="primary" aria-label="facebook">
              <FacebookRounded />
            </Fab>
          </div>
        </a>
      );
    } else if (url.includes("github")) {
      return (
        <a href={url} target="_blank">
          <div className="linkIcon">
            <Fab size="small" color="primary" aria-label="github">
              <AiFillGithub />
            </Fab>
          </div>
        </a>
      );
    } else if (url.includes("stackoverflow")) {
      return (
        <a href={url} target="_blank">
          <div className="linkIcon">
            <Fab size="small" color="primary" aria-label="stackoverflow">
              <BsStackOverflow />
            </Fab>
          </div>
        </a>
      );
    } else if (url.includes("twitter")) {
      return (
        <a href={url} target="_blank">
          <div className="linkIcon">
            <Fab size="small" color="primary" aria-label="twitter">
              <AiOutlineTwitter />
            </Fab>
          </div>
        </a>
      );
    } else if (url.includes("linkedin")) {
      return (
        <a href={url} target="_blank">
          <div className="linkIcon">
            <Fab size="small" color="primary" aria-label="linkedin">
              <AiFillLinkedin />
            </Fab>
          </div>
        </a>
      );
    } else if (url.includes("insta")) {
      return (
        <a href={url} target="_blank">
          <div className="linkIcon">
            <Fab size="small" color="primary" aria-label="instagram">
              <AiFillInstagram />
            </Fab>
          </div>
        </a>
      );
    } else {
      return null;
    }
  });
}
