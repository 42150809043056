import Button from "@mui/material/Button";

export default function MUIButton({ children, destination, ...props }){
    return (
      <Button
      {...props}
        color="primary"
        onClick={() => window.open(destination, "_blank")} // Opens the link in a new tab
        style={{ margin: "10px 0" }}
      >
        {children}
      </Button>
    );
  };