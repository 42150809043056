import { marked } from "marked";
import PlainTextRenderer from "marked-plaintext";
import sanitizeHtml from "sanitize-html";
import axios from "axios"; // Import axios
import base64 from "react-native-base64";
import { useEffect } from "react";


export function capitaliseFirstChars(str) {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }
  return arr.join(" ");
}

export function getReadTime(str) {
  const readingTime = require("reading-time/lib/reading-time");
  const stats = readingTime(str);

  return Math.ceil(stats.minutes);
}

const plaintextOptions = {
  sanitize: false,
  gfm: true,
  xhtml: true,
};

export function convertToPlainText(markdownText) {
  const renderer = new PlainTextRenderer();
  renderer.checkbox = (text) => {
    return text;
  };
  marked.setOptions(plaintextOptions);
  var sanitesedText = sanitizeHtml(marked(markdownText, { renderer }), {
    allowedTags: [],
    disallowedTagsMode: "discard",
  });
  var sanitesedTextArr = sanitesedText
    .replaceAll(/[^\w]\d*\s*/g, " ")
    .trim()
    .toLowerCase()
    .split(" ");
  var cleanSet = new Set(sanitesedTextArr);
  return Array.from(cleanSet).join(" ");
}

export const getGitHubFileContent = async (fileName) => {
  const token = process.env.REACT_APP_GITHUB_TOKEN; // Fetch the token from .env file

  const options = {
    method: "GET",
    url: `https://api.github.com/repos/NeilSayok/imagelib/contents/${fileName}`,
    headers: {
      Accept: "application/vnd.github+json",
      Authorization: `Bearer ${token}`,
      "X-GitHub-Api-Version": "2022-11-28",
    },
  };

  try {
    const response = await axios(options);
    var base64Content = response.data.content; // Extract the "content" field from the JSON response
    base64Content = base64Content.replace(/\s/g, "");
    const decodedContent = base64.decode(base64Content); // Decode using react-native-base64
    return decodedContent; // Return the decoded content
  } catch (error) {
    console.error("Error=blog", error);
    throw error;
  }
};


const applyRules = (str) => {
  // Replace '-' with ' '
  let result = str.replace(/-/g, ' ');

  // Remove leading numbers followed by a period
  result = result.replace(/^\d+\./, '');

  // Remove the last occurrence of '.md' if it exists
  if (result.endsWith('.md')) {
    result = result.slice(0, -3);
  }

  // Split the result by '_' and return only the first two parts
  return result.split('_', 2);
};


export const getFolderFromGithub = async(folderName) => {

  const token = process.env.REACT_APP_GITHUB_TOKEN;
  const options = {
    method: "GET",
    url: `https://api.github.com/repos/NeilSayok/imagelib/contents/${folderName}`,
    headers: {
      Accept: "application/vnd.github+json",
      Authorization: `Bearer ${token}`,
      "X-GitHub-Api-Version": "2022-11-28",
    },
  };
  try {
    const response = await axios(options);
    return response.data;
  }catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }

}

// Fetch projects and add the `data` field
export const getMyProjects = async () => {
  
  try {
    const projects = await getFolderFromGithub("projects")

    // For each project, get additional data and add the `data` field
    const enrichedProjects = await Promise.all(
      projects
      .filter(project => project.name.endsWith('.md'))
      .map(async (project) => {
        // if (project.name.endsWith(".md")) {
          // Get additional data for the project

          const additionalData = await getGitHubFileContent(project.path);
          const [title, subtitle] = applyRules(project.name)
          console.log(title, subtitle);
          


          // Add the `data` field to each project object
          return {
            ...project,
            title : title,
            subtitle : subtitle,
            data: additionalData, // Add your custom data here
          };
        // }
      })
    );

    return enrichedProjects;
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }
};

export const getMyJobs = async() => {

  try {
    const projects = await getFolderFromGithub("jobs")

    // For each project, get additional data and add the `data` field
    const enrichedProjects = await Promise.all(
      projects
      .filter(project => project.name.endsWith('.md'))
      .map(async (project) => {
        // if (project.name.endsWith(".md")) {
          // Get additional data for the project

          const additionalData = await getGitHubFileContent(project.path);
          const [title, subtitle] = applyRules(project.name)

          // Add the `data` field to each project object
          return {
            ...project,
            title : title,
            data: additionalData, // Add your custom data here
          };
        // }
      })
    );

    return enrichedProjects;
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }

}


export default function useClickOutside(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the click was outside of the referenced element
      if (ref.current && !ref.current.contains(event.target)) {
        callback(); // Call the provided callback function
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}
