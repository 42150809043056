import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import { Box, Card, IconButton } from "@mui/material";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import MarkdownHandler from "../MarkdownHandler/MarkdownHandler";
import "./ProjectCard.css";

export default function ProjectCard({project}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(project)

  
  return (
    <motion.div ref={ref} layout onClick={() => setIsOpen(!isOpen)} className={isOpen ? "card-expanded" : "card"}>
      <motion.div layout="position" >
      <Box sx={{ display: "flex", alignItems: "center" }} onClick={() => setIsOpen(!isOpen)}>
          <Card onClick={() => setIsOpen(!isOpen)}
            sx={{
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 32,
              width: 32,
              marginRight: 1,
              marginLeft: 1,
            }}
          >
            <ThunderstormIcon onClick={() => setIsOpen(!isOpen)}></ThunderstormIcon>
          </Card>
          <h3 sx={{ flex: 1 }} onClick={() => setIsOpen(!isOpen)}>{project.title}</h3>
          <IconButton onClick={() => setIsOpen(!isOpen)}>
            <ExpandCircleDownIcon />
          </IconButton>
        </Box>
      </motion.div>
      {isOpen && (
        <motion.div>
          <Box>
              <h3>Description: {project.subtitle}</h3>
              <MarkdownHandler input={project.data} />
          </Box>
        </motion.div>
      )}
    </motion.div>
  );
}
