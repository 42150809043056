import Box from "@mui/material/Box";
import {
  alpha,
  AppBar,
  Button,
  IconButton,
  InputBase,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import BlueLabsICWhite from "../../Icons/png/Blue_Labs_Icon_White.png";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { capitaliseFirstChars } from "../../Functions/Helper";
import { useNavigate, useLocation } from "react-router-dom";
import PolicyIcon from '@mui/icons-material/Policy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useState, useEffect } from "react";

const Search = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "26ch",
      "&:focus": {
        width: "30ch",
      },
    },
  },
}));

export default function BlogAppBar({ genre, route }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchText, setSearchText] = useState("");

  const navigateToHomeOnClick = () => {
    navigate("/");
  };

  const navigateToSearchOnEnter = (e) => {
    if (e.keyCode === 13) {
      navigate(`/search/${e.target.value}`);
    }
  };

  const navigateToMerchStoreOnClick = () => {
    navigate("/privacy-policy");
  }; 
  
  const navigateToPortfolioOnClick = () => {
    navigate("/portfolio");
  };

  useEffect(() => {
    if (!location.pathname.includes("search")) {
      setSearchText("");
    } else {
      var s = location.pathname.split("/");
      setSearchText(s[s.length - 1]);
    }
  }, [location.pathname]);

  return (
    <Box classes="appbarRoot">
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ display: "flex", mr: 1, height: "32px" }}
              component="img"
              alt="App Logo"
              src={BlueLabsICWhite}
              onClick={navigateToHomeOnClick}
            />

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
              }}
              onClick={navigateToHomeOnClick}
            >
              BLUE LABS
            </Typography>
            {genre !== undefined && (
              <Typography
                variant="p"
                noWrap
                sx={{
                  mr: 2,
                  display: { xs: "none", sx: "flex" },
                  fontWeight: 600,
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                {"@" + capitaliseFirstChars(genre)}
              </Typography>
            )}

            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onKeyDown={(e) => {
                  navigateToSearchOnEnter(e);
                }}
              />
            </Search>
          </Box>
          <Tooltip title="Privacy & Policy">
            <Box>
              <Button
                color="inherit"
                sx={{
                  display: { xs: "none", sm: "flex" },
                }}
                onClick={navigateToMerchStoreOnClick}
              >
                <PolicyIcon />
                <Box sx={{margin : "2px"}}/>
                Privacy Policy
              </Button>

              <IconButton
                color="inherit"
                sx={{
                  display: { xs: "flex", sm: "none" },
                }}
                aria-label="privacy"
                onClick={navigateToMerchStoreOnClick}
              >
                <PolicyIcon />
              </IconButton>
            </Box>
          </Tooltip>

          <Tooltip title="About Me">
            <Box>
              <Button
                color="inherit"
                sx={{
                  display: { xs: "none", sm: "flex" },
                }}
                onClick={navigateToPortfolioOnClick}
              >
                <AccountCircleIcon />
                <Box sx={{margin : "2px"}}/>
                About Me
              </Button>

              <IconButton
                color="inherit"
                sx={{
                  display: { xs: "flex", sm: "none" },
                }}
                aria-label="privacy"
                onClick={navigateToPortfolioOnClick}
              >
                <AccountCircleIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
