import {
  Alert,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Snackbar,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { capitaliseFirstChars, getReadTime } from "../../../Functions/Helper";
import "./HomeCardMid.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

export default function HomeCardMid({ blogItem, authorItem, genreItem }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const navigateToOnClick = (e, name, uri) => {
    if (e.target.id !== "shareBtn") navigate(`/${name}/${uri}`);
    else shareButtonClicked();
  };

  const shareButtonClicked = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Card
        id="root"
        sx={{ maxWidth: 300 }}
        onClick={(e) =>
          navigateToOnClick(e, authorItem["uid"], blogItem["url_str"])
        }
      >
        <CardMedia
          component="img"
          height="140"
          image={blogItem["big_img"]}
          alt={blogItem["title"]}
        />

        <CardContent>
          <Chip size="small" label={capitaliseFirstChars(genreItem["type"])} />
          <h4 className="homeCardTitle">{blogItem["title"]}</h4>

          <div className="authorDiv">
            <Avatar
              alt={authorItem["name"]}
              src={authorItem["img_url"]}
              sx={{ width: 24, height: 24 }}
            />
            <p className="authNameP">{authorItem["name"]}</p>
          </div>

          <p className="readTimeP">{blogItem["read_time"]} mins read</p>
        </CardContent>

        <CardActions>
          <CopyToClipboard
            id="shareBtn"
            text={
              window.location.href +
              authorItem["uid"] +
              "/" +
              blogItem["url_str"]
            }
            onCopy={shareButtonClicked}
          >
            <Button size="small" onClick={() => shareButtonClicked}>
              Share
            </Button>
          </CopyToClipboard>
        </CardActions>
      </Card>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Note archived"
      >
        <Alert severity="success">Blog post URL copied to clipboard!</Alert>
      </Snackbar>
    </div>
  );
}
