import { useState } from "react";
import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";

const ExpandableFab = ({ icon, text, bgColor,contentColor,...props }) => {
  const [variant, setVariant] = useState("circular");

  const CustomFab = styled(Fab)(({ bgColor,contentColor,  }) => ({
    backgroundColor: bgColor, // Custom background color
    color: contentColor, // Custom icon color
    "&:hover": {
      backgroundColor: bgColor, // Custom hover background color
    },
  }));

  return (
    <CustomFab
      bgColor = {bgColor}
      contentColor = {contentColor}
      variant={variant}
      onMouseEnter={() => setVariant("extended")}
      onMouseLeave={() => setVariant("circular")}
      size={variant == "extended" ? "large" : "medium"}
      {...props}
    >
      <>{icon}</>
      {variant == "extended" ? <p>{text}</p> : <></>}
    </CustomFab>
  );
};

export default ExpandableFab;
