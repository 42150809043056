import { Box, Card, CircularProgress } from "@mui/material";
import "./MorePage.css";
import MPBasicItem from "../MorePageItems/MPBasicItem";
import { useEffect, useState } from "react";
import { getDocs, limit, orderBy, query, where } from "firebase/firestore";
import {
  authorCollectinRef,
  blogCollectionRef,
} from "../firebase/firebase-config";

export default function MorePages({ current_url }) {
  const [blogs, setBlogs] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getBlogs = async () => {
    const blgQry = query(
      blogCollectionRef,
      where("isPublished", "==", true),
      where("url_str", "!=", current_url),
      orderBy("url_str"),
      limit(5)
    );
    const data = await getDocs(blgQry);
    var out = data.docs.map((doc) => ({ ...doc.data() }));
    return out;
  };

  const getAuthors = async () => {
    const data = await getDocs(authorCollectinRef);
    var out = new Map();
    data.docs.map((doc) => out.set(doc.id, { ...doc.data() }));
    return out;
  };

  useEffect(() => {
    getBlogs()
      .then((data) => {
        setBlogs(data);
      })
      .then(
        getAuthors().then((data) => {
          setAuthors(data);
          setIsLoading(false);
        })
      );
  }, []);

  return (
    <Box className="morePageRoot">
      {isLoading ? (
        <Box className="isLoadingDivMorePages">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {blogs && blogs.length ? (
            <Card variant="outlined">
              <p className="moreTitle">More from Blue Labs</p>

              {blogs.map((blog) => (
                <MPBasicItem
                  authAvatar={authors.get(blog["author"]["id"])["img_url"]}
                  authName={authors.get(blog["author"]["id"])["name"]}
                  blogTitle={blog["title"]}
                  taglist={blog["tags"]}
                  blogIcon={blog["tiny_img"]}
                  blogURL={`/${authors.get(blog["author"]["id"])["uid"]}/${
                    blog["url_str"]
                  }`}
                />
              ))}
            </Card>
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  );
}
