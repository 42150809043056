import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import "./CodeHighlighter.css";
import { Card, IconButton, Snackbar, Tooltip } from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { forwardRef, useState } from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function cleanChildren(children) {
  for (var i = 0; i < children.length; i++) {
    children[i] = children[i].trim();
  }
  return children;
}

function CodeHighlight({ node, inline, className, children, ...props }) {
  const [isIconVisible, setIsIconVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  var lang = "";
  try {
    lang = className.replace("language-", "");
  } catch {
    lang = "";
  }

  return (
    <div
      className="highlightRoot"
      onMouseEnter={() => setIsIconVisible(true)}
      onMouseLeave={() => setIsIconVisible(false)}
    >
      {inline ? (
        <CopyToClipboard text={children.toString()} onCopy={handleClick}>
          <Tooltip title="Click to copy code!" arrow>
            <Card
              className="inlineCode"
              variant="outlined"
              sx={{ background: "#4b4b59" }}
            >
              <code className="codeBlock">{children}</code>
            </Card>
          </Tooltip>
        </CopyToClipboard>
      ) : (
        <div>
          <SyntaxHighlighter
            language={lang}
            style={dracula}
            showLineNumbers={!inline}
            startingLineNumber={1}
            children={cleanChildren(children)}
          />
          <CopyToClipboard text={children.toString()} onCopy={handleClick}>
            <Tooltip title="Click to copy code!" arrow>
              <IconButton
                className="iconBtn"
                sx={{
                  visibility: isIconVisible ? "visible" : "hidden",
                  position: "absolute",
                }}
                aria-label="Copy"
              >
                <CopyAll className="iconBtnIcon" />
              </IconButton>
            </Tooltip>
          </CopyToClipboard>
        </div>
      )}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Note archived"
      >
        <Alert severity="success">Code copied to clipboard!</Alert>
      </Snackbar>
    </div>
  );
}

export default CodeHighlight;
