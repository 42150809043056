import {
  Alert,
  Button,
  Card,
  Chip,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import ExpandableFab from "../../Components/ExpandableFab/ExpandableFab";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import RatingStar from "../../Components/RatingStar/RatingStar";
import { getMyJobs, getMyProjects } from "../../Functions/Helper";
import IC_CALL from "../../Icons/svg/ic_call";
import IC_EMAIL from "../../Icons/svg/ic_email";
import IC_MAILTO from "../../Icons/svg/ic_open_mailto";
import IC_WARNING from "../../Icons/svg/is_warning";
import WhatsappIcon from "../../Icons/svg/whatsapp";
import MarkdownHandler from "../../Components/MarkdownHandler/MarkdownHandler";
import { Helmet } from "react-helmet";

export function Meta(){
  return(
  <Helmet>
    <title>Portfolio</title>
    <meta name="title" content={"Portfolio"} />
    <meta name="sub-title" content={"About Me"} />

  </Helmet>);
};

export default function PortfolioPage() {
  function TabPanel(props) {
    const { input, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Card sx={{paddingLeft: 4,paddingRight: 4}}>
            <MarkdownHandler input={input} />
          </Card>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const scrollToSectionRef = useRef(null);
  const inputRef = useRef(null);

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState(
    "Please enter subject and message"
  );

  const [projects, setProjects] = useState([]);
  const [jobs, setJobs] = useState([]);

  const sendMessage = () => {
    if (subject.trim() != "" && message.trim() != "") {
      const encodedMessage = encodeURIComponent(
        `*Subject : ${subject.trim()}*\n${message}`
      );
      const url = `https://wa.me/+919051880247?text=${encodedMessage}`;
      window.open(url, "_blank"); // Opens the WhatsApp link in a new tab or window
    } else {
      setShowSnack(true);
    }
    setShowWarning(false);
  };

  const initiateCall = () => {
    window.open(`tel:+919051880247`, "_blank");
    // window.location.href = `tel:+919051880247`;
  };

  const openEmailClient = () => {
    if (subject.trim() != "" && message.trim() != "") {
      const encodedSubject = encodeURIComponent(subject);
      const encodedBody = encodeURIComponent(message);
      const mailtoLink = `mailto:sayokdeymajumder1998@gmail.com?subject=${encodedSubject}&body=${encodedBody}`;
      window.open(mailtoLink, "_blank");
    } else {
      setShowSnack(true);
    }

    setShowWarning(true);
  };

  useEffect(() => {
    getMyProjects().then((proj) => {
      console.log(proj);
      setProjects(proj);
    });
  }, []);
  useEffect(() => {
    getMyJobs().then((jobs) => {
      console.log(jobs);
      setJobs(jobs);
    });
  }, []);

  const handleScrollAndFocus = () => {
    // Scroll to the section
    if (scrollToSectionRef.current) {
      scrollToSectionRef.current.scrollIntoView({ behavior: "smooth" });

      // Request focus after scrolling, using a timeout to ensure the scroll completes first
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 500); // Adjust the timeout as needed
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnack(false);
  };

  const handleDownload = () => {
    const fileId = "1iCpE7Fr72y9SwoZuKQqOVyEZg2MeREgoiKpEJ3BkN0Q"; // Replace with your Google Docs file ID
    const url = `https://docs.google.com/document/d/${fileId}/export?format=pdf`;

    // Create a hidden link element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Open in a new tab
    link.download = "RESUME_SAYOK_DEY_MAJUMDER.pdf"; // Optional: file name
    link.click();
  };

 

  return (
    <Box sx={{ margin: { md: 8, xs: 4 } }}>
      <Meta/>
      <Box>
        <h1>Hi I am Sayok</h1>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ marginRight: 1 }}>My Motto:</Typography>
          <Typewriter
            words={["Eat", "Sleep", "Code", "Repeat!"]}
            loop={false}
            cursor
            cursorStyle="|"
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
            // onLoopDone={handleDone}
            // onType={handleType}
          />
        </Box>
      </Box>
      <Box>
        <h3>About me</h3>
        <Typography>
          👋 Hey there! I’m Sayok Dey Majumder, a Google Certified Android
          Developer who believes that good code should be as well-injected as my
          morning coffee ☕︎. I’m passionate about building apps that are
          smoother than a perfectly resolved dependency tree and more reliable
          than my singleton instances. Whether I’m refactoring code or making
          sure my modules are loosely coupled (unlike my attachment to my
          keyboard), I’m always on a mission to ensure my apps don’t just
          work—they wow. And when I’m not coding, you’ll find me pondering why
          life didn’t come with dependency injection—would’ve made finding the
          right coffee easier!
        </Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Button variant="contained" onClick={handleDownload}>
          Resume
        </Button>
        <Button
          variant="contained"
          sx={{ marginLeft: 1 }}
          onClick={handleScrollAndFocus}
        >
          Contact Me
        </Button>
      </Box>

      <Box>
        <h3>Skills</h3>
        <Grid container spacing={1}>
          <Chip
            deleteIcon={<RatingStar stars={5} />}
            onDelete={{}}
            label="Android"
          />
          <Chip
            label="Kotlin"
            deleteIcon={<RatingStar stars={5} />}
            onDelete={{}}
          />
          <Chip
            label="Java"
            deleteIcon={<RatingStar stars={4} />}
            onDelete={{}}
          />
          <Chip
            label="Jetpack Compose"
            deleteIcon={<RatingStar stars={5} />}
            onDelete={{}}
          />
        </Grid>
      </Box>

      {projects.length <= 0 ? (
        <></>
      ) : (
        <Box>
          <h3>Projects</h3>
          <Grid container spacing={1}>
            {projects.map((project) => (
              <ProjectCard project={project} />
            ))}
          </Grid>
        </Box>
      )}

      {jobs.length <= 0 ? (
        <></>
      ) : (
        <Box>
          <h3>Where I've worked</h3>
          <Tabs value={value} onChange={handleChange}>
            {jobs.map((job, index) => (
              <Tab label={job.title} {...a11yProps(index)}></Tab>
            ))}
          </Tabs>

          <Box sx={{ marginTop: 1 }} />

          {jobs.map((job, index) => (
            <TabPanel value={value} index={index} input={job.data}></TabPanel>
          ))}
        </Box>
      )}

      <Box>
        <h3>Contact Me</h3>

        <p>
          I am currently looking for new opportunities, my inbox is always open.
          If you have any questions about my services, or just want to say
          hello, feel free to contact me.
        </p>

        <Card ref={scrollToSectionRef}>
          <Box display="flex" flexDirection="column" sx={{ padding: 4 }}>
            <TextField
              inputRef={inputRef}
              id="outlined-basic"
              label="Subject"
              variant="outlined"
              onChange={(e) => setSubject(e.target.value)}
            />

            <TextField
              id="filled-multiline-static"
              label="Message"
              multiline
              rows={5}
              sx={{ marginTop: 2 }}
              variant="outlined"
              onChange={(e) => setMessage(e.target.value)}
            />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ marginTop: 4 }}
            >
              <ExpandableFab
                bgColor="#68CE60"
                contentColor="#FFFFFF"
                icon={<WhatsappIcon color="#FFFFFF" />}
                text={"Whatsapp"}
                onClick={sendMessage}
              />
              <ExpandableFab
                sx={{ marginLeft: 1 }}
                bgColor="#DB5443"
                contentColor="#FFFFFF"
                icon={<IC_EMAIL color="#FFFFFF" />}
                text={"Email"}
                onClick={openEmailClient}
              />
              <ExpandableFab
                sx={{ marginLeft: 1 }}
                bgColor="#3874CB"
                contentColor="#FFFFFF"
                icon={<IC_CALL color="#FFFFFF" />}
                text={"Call"}
                onClick={initiateCall}
              />
            </Box>
          </Box>
        </Card>

        {showWarning ? (
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
            <IC_WARNING color="#ED4337" />
            <Typography
              sx={{ marginLeft: 1, marginRight: 1, color: "#ED4337" }}
            >
              If your mail box is not opening, please open you mail box in the
              browser and click on the{" "}
            </Typography>
            <IC_MAILTO color="#ED4337" />
            <Typography sx={{ marginLeft: 1, color: "#ED4337" }}>
              {" "}
              in the address bar and the click the Email button again.
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Snackbar
        open={showSnack}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackMessage}
      >
        <Alert severity="error">{snackMessage}</Alert>
      </Snackbar>
    </Box>
  );
}
