import { Box, Button, Typography } from "@mui/material";
import KeepCalmCrown from "../../Icons/png/Keep_Calm_Crown.png";
import { useLocation } from "react-router-dom";
import { send_analitics_page_view } from "../../Components/firebase/firebase-config";
import { useNavigate } from "react-router-dom";

export default function Store() {
  const location = useLocation();
  send_analitics_page_view(location);
  const navigate = useNavigate();

  const navigateToOnClick = (e) => {
    navigate(`/privacy-policy`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        minHeight: "100%",
        flex: 1,
      }}
    >
      <Box
        component="img"
        alt="Keep Calm"
        src={KeepCalmCrown}
        sx={{ height: "80px" }}
      />
      <Typography variant="h3" sx={{ fontWeight: "bold" }}>
        KEEP CALM
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        NEW
      </Typography>
      <Typography variant="h3" sx={{ fontWeight: "bold" }}>
        MERCHANDISE
      </Typography>
      <Typography variant="h3" sx={{ fontWeight: "bold" }}>
        COMING SOON
      </Typography>

      <Button
      onClick={(e) =>
        navigateToOnClick(e)}
      >Visit Privacy Policy</Button>
    </Box>
  );
}
