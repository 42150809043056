import { Avatar, Box, Card, Chip } from "@mui/material";
import TagIcon from "@mui/icons-material/Tag";

import "./MPBasicItem.css";
import { styled } from "@mui/material/styles";
import { capitaliseFirstChars } from "../../Functions/Helper";
import { useNavigate } from "react-router-dom";

const options = {
  shouldForwardProp: (prop) => prop !== "deleteIconColor",
};
const StyledChip = styled(
  Chip,
  options
)(() => ({
  "& .MuiChip-icon": {
    width: 16,
    height: 16,
    color: "#a1a1a1",
  },
  "& .MuiChip-label": {
    fontSize: "0.7em",
  },
}));

export default function MPBasicItem({
  authAvatar,
  authName,
  blogTitle,
  taglist,
  blogIcon,
  blogURL,
}) {
  const navigate = useNavigate();

  const goToPageOnClick = () => {
    navigate(blogURL);
    navigate(0);
  };

  return (
    <Box>
      <Card
        variant="outlined"
        className="rootMPBasic"
        onClick={goToPageOnClick}
      >
        <Box className="authDiv">
          <Avatar
            className="authAvatar"
            sx={{ width: 20, height: 20 }}
            alt={authName}
            src={authAvatar}
          />
          <p className="authName">{authName}</p>
        </Box>
        <Box className="mpBasicItemBody">
          <Box sx={{ flex: 1 }}>
            <Box>
              <p className="blogTitleText">{blogTitle}</p>
            </Box>
          </Box>
          <Box className="blogAvatarContainer">
            <Avatar
              sx={{ width: 50, height: 50 }}
              src={blogIcon}
              alt={blogTitle}
            />
          </Box>
        </Box>
        <Box>
          <Box sx={{ alignItems: "flex-start" }}>
            {taglist.map((tag) => (
              <StyledChip
                icon={<TagIcon />}
                label={capitaliseFirstChars(tag)}
                sx={{ fontSize: ".70em" }}
                size="small"
                className="mpBasicChip"
              />
            ))}
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
