import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MarkdownHandler from "../../Components/MarkdownHandler/MarkdownHandler";
import "./BlogPage.css";
import CardMedia from "@mui/material/CardMedia";
import { Helmet } from "react-helmet";
import {
  convertToPlainText,
  getGitHubFileContent,
} from "../../Functions/Helper";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  CircularProgress,
  ThemeProvider,
} from "@mui/material";
import TagIcon from "@mui/icons-material/Tag";
import { createTheme } from "@mui/material/styles";
import AuthDetails from "../../Components/AuthorDetails/AuthDetails";
import MorePages from "../../Components/MorePages/MorePage";
import { capitaliseFirstChars } from "../../Functions/Helper";
import { getDocs, query, where } from "firebase/firestore";
import {
  authorCollectinRef,
  blogCollectionRef,
  profileCollectionRef,
  send_analitics_page_view,
} from "../../Components/firebase/firebase-config";
import { useEffect, useState } from "react";
import dateformat from "dateformat";
import { useLocation, useParams } from "react-router-dom";

const theme = createTheme({
  palette: {
    chip0: {
      main: "#32c95b",
      contrastText: "#fff",
    },
    chip1: {
      main: "#eb3169",
      contrastText: "#fff",
    },
    chip2: {
      main: "#31b9eb",
      contrastText: "#fff",
    },
    chip3: {
      main: "#fcd03d",
      contrastText: "#fff",
    },
  },
});

export default function BlogPage() {
  const params = useParams();
  const location = useLocation();

  send_analitics_page_view(location);

  const [blog, setBlog] = useState([]);
  const [author, setAuthor] = useState([]);
  const [authProfiles, setAuthProfiles] = useState([]);
  const [auth, setauthDoc] = useState();
  const [readme, setReadme] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const authQry = query(authorCollectinRef, where("uid", "==", params.author));

  const getBlogs = async () => {
    const blgQry = query(
      blogCollectionRef,
      where("url_str", "==", params.bloguri)
    );
    const data = await getDocs(blgQry);
    var b = data.docs.map((doc) => ({ ...doc.data() }));
    return b[0];
  };

  const getAuthor = async () => {
    const authDoc = await getDocs(authQry);
    setauthDoc(authDoc);
    var d = authDoc.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    return d[0];
  };

  const getAuthProfiles = async () => {
    const authProfiles = await getDocs(
      profileCollectionRef,
      author["profiles"]
    );
    var d = authProfiles.docs.map((doc) => ({ ...doc.data() }));
    var list = d[0];
    var keysSorted = Object.values(list).sort(function (a, b) {
      return list[a] - list[b];
    });
    return keysSorted;
  };

  useEffect(() => {
    getAuthor().then((ath) => {
      setAuthor(ath);
      getBlogs().then((blg) => {
        setBlog(blg);
        getGitHubFileContent(blg["readme_file"]).then((decodedContent) => {
          setReadme(decodedContent);
        });
        getAuthProfiles().then((prf) => {
          setAuthProfiles(prf);
          setIsLoading(false);
        });
      });
    });
  }, [location.key]);

  return (
    <Box sx={{ display: "flex" }}>
      {isLoading ? (
        <div className="isLoadingDiv">
          <CircularProgress />
        </div>
      ) : (
        // className="rootDiv"
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Card
            sx={{
              width: "100%",
              flex: 6,
              borderRadius: { md: 4, xs: "0px 0px 8px 8px" },
              boxShadow: 4,
              order: 1,
              margin: { md: "16px", xs: "0px" },
              overflow: { md: "hidden", xs: "visible" },
            }}
            variant="outlined"
            className="blogRootCard"
          >
            <CardMedia
              className="cardHeaderMedia"
              component="img"
              image={blog.big_img}
              title="Blog Header Image"
            />
            <Helmet>
              <meta name="image" content={blog.big_img} />
            </Helmet>
            <Helmet>
              <meta name="og:image" content={blog.big_img} />
            </Helmet>
            <CardContent sx={{ padding: "16px" }}>
              <h1
                className="titleText"
                sx={{ fontSize: { md: "2.2rem", xs: "1.8rem" } }}
              >
                {blog.title}
                <Helmet>
                  <title>{blog.title}</title>
                  <meta name="title" content={blog.title} />
                </Helmet>
              </h1>

              <div style={{ display: "flex" }}>
                <Avatar
                  sx={{ width: 32, height: 32 }}
                  alt={author["name"]}
                  src={author["img_url"]}
                />

                <Helmet>
                  <meta name="author" content={author["name"]} />
                  <meta name="author_img" content={author["img_url"]} />
                </Helmet>

                <div className="authTextHolder">
                  <p className="namePara">{author["name"]}</p>
                  <p className="postedPara">
                    Posted On:{" "}
                    {dateformat(blog.posted.toDate(), "dS mmmm yyyy")}
                    <Helmet>
                      <meta
                        name="posted_on"
                        content={dateformat(
                          blog.posted.toDate(),
                          "dS mmmm yyyy"
                        )}
                      />
                    </Helmet>
                  </p>
                </div>
              </div>

              <ThemeProvider theme={theme}>
                <Box sx={{ marginLeft: 1, marginTop: 2 }}>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 3 }}
                    columns={{ xs: 4, sm: 9, md: 20 }}
                  >
                    {blog["tags"].map((tag, index) => (
                      <Chip
                        sx={{ marginLeft: 1, marginTop: 2 }}
                        icon={<TagIcon />}
                        label={capitaliseFirstChars(tag)}
                        color={`chip${index}`}
                        variant="outlined"
                      />
                    ))}

                    <Helmet>
                      <meta name="tags" content={blog["tags"].join(",")} />
                    </Helmet>
                  </Grid>
                </Box>
              </ThemeProvider>
            </CardContent>
            <CardContent sx={{ padding: { md: "16px", xs: "4px" } }}>
              <div className="blogMD">
                {readme.length <= 0 ? (
                  <CircularProgress />
                ) : (
                  <MarkdownHandler input={readme} />
                )}

                <meta name="description" content={convertToPlainText(readme)} />
              </div>
            </CardContent>
          </Card>
          <Box
            sx={{
              width: "100%",
              flex: 1,
              marginTop: 8,
              margin: { md: "16px", xs: "0px" },
              order: 2,
            }}
          >
            <Box>
              <AuthDetails
                sx={{ width: "100%", marginTop: { md: 4, xs: 6 } }}
                name={author["name"]}
                avatar={author["img_url"]}
                bio={author["bio"]}
                location={author["location"]}
                profiles={authProfiles}
                uid={author["uid"]}
              />
            </Box>
            <Box>
              <MorePages current_url={params.bloguri} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
