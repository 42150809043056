import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getDocs, query, where } from "firebase/firestore";
import {
  authorCollectinRef,
  profileCollectionRef,
  send_analitics_page_view,
} from "../../Components/firebase/firebase-config";
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CircularProgress,
  p,
  Stack,
  Button,
} from "@mui/material";
import dateformat from "dateformat";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ProfileFab from "../../Components/ProifleFAB/ProfileFab";
import "./ProfilePage.css";
import { Helmet } from "react-helmet";

export default function ProfilePage() {
  const params = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const navigateToOnClick = (e) => {
    navigate(`/privacy-policy`);
  };

  send_analitics_page_view(location);
  const [author, setAuthor] = useState([]);
  const [authProfiles, setAuthProfiles] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const getAuthors = async () => {
    const authQuery = query(authorCollectinRef, where("uid", "==", params.uid));
    const data = await getDocs(authQuery);
    var out = data.docs.map((doc) => ({ ...doc.data() }));
    return out[0];
  };

  const getProfiles = async () => {
    const ap = await getDocs(profileCollectionRef, author["profiles"]);
    var d = ap.docs.map((doc) => ({ ...doc.data() }));
    var list = d[0];
    return Object.values(list).sort(function (a, b) {
      return list[a] - list[b];
    });
  };

  useEffect(() => {
    getAuthors().then((auth) => {
      setAuthor(auth);
      getProfiles().then((data) => {
        setAuthProfiles(data);
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <div>
      {author.length === 0 ? (
        <div className="isLoadingDiv">
          <CircularProgress />
        </div>
      ) : (
        <Card className="prodileRootCard">
          <Avatar
            className="avatar"
            sx={{ width: 96, height: 96 }}
            alt={author["name"]}
            src={author["img_url"]}
          />

          <Helmet>
            <title>{author["name"]}</title>
            <meta
              name="title"
              content="Welcome to Blue Labs, find awesome tutorials and blogs here"
            />
            <meta name="description" content={author.bio} />
            <meta name="image" content={author["img_url"]} />
          </Helmet>

          <p className="nameText">{author["name"]}</p>

          <p className="bioText">{author.bio}</p>

          <Stack direction="row" spacing={2}>
            <div className="profileItems">
              <LoyaltyIcon sx={{ marginRight: 1 }} />
              <p>
                Member Since:
                {dateformat(author.joined.toDate(), "dS mmmm yyyy")}
              </p>
            </div>
            <div className="profileItems">
              <MyLocationIcon sx={{ marginRight: 1 }} />
              <p>{author.location}</p>
            </div>
          </Stack>

          <div className="profileDiv">
            <ProfileFab profiles={authProfiles} />
          </div>
        </Card>
      )}
      <div className="privacyButton">
        <Button onClick={(e) => navigateToOnClick(e)}>
          Visit Privacy Policy
        </Button>
      </div>
    </div>
  );
}
