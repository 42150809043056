import BlueLabsICColored from "../../../Icons/blue_labs_ic_colored_64";
import { useLocation } from "react-router-dom";
import { send_analitics_page_view } from "../../../Components/firebase/firebase-config";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';


export default function PageNotFound() {
  const location = useLocation();
  send_analitics_page_view(location);
  return (
    <Box sx={{display: "flex", flexDirection:"column", justifyContent: "center", alignItems:"center", width: "100vw", height:"100vh"}}>
      <BlueLabsICColored/>
      <Typography variant="h3" component="h1" sx={{textAlign: "center", margin:4}}>
        Oops! You weren't suppposed to see this page.      </Typography>
    </Box>
  );
}
