import { Card } from "@mui/material";

export default function ADItem() {
  return (
    <div>
      <Card
        sx={{
          width: "100%",
          height: "100px",
          backgroundColor: "teal",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p> Ad Item</p>
      </Card>
    </div>
  );
}
