import { Box } from "@mui/system";
import IC_STAR from "../../Icons/svg/ic_star";

export default function RatingStar({stars}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginRight : 1
      }}
    >
      <IC_STAR />
      <p>{stars}</p>
    </Box>
  );
}
