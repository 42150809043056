import "./WithEditor.css";
import { useEffect, useRef, useState } from "react";
import MarkdownHandler from "../../Components/MarkdownHandler/MarkdownHandler";
import {
  Alert,
  Button,
  Card,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import {
  authorCollectinRef,
  blogCollectionRef,
  db,
  genreCollectionRef,
  send_analitics_page_view,
} from "../../Components/firebase/firebase-config";
import { sha256 } from "js-sha256";
import { useLocation } from "react-router-dom";
import { getGitHubFileContent, getReadTime } from "../../Functions/Helper";

export default function WithEditor() {
  const location = useLocation();

  send_analitics_page_view(location);

  const [genreList, setGenreList] = useState([]);
  const [authList, setAuthList] = useState([]);
  const [open, setOpen] = useState(false);

  const [genre, setGenre] = useState();
  const [tinyImgUrl, setTinyImgUrl] = useState("");
  const [bigImgUrl, setBigImgUrl] = useState("");
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [mdFile, setMdFile] = useState("")
  const [markdownInput, setMarkdownInput] = useState("");
  const [author, setAuthor] = useState();

  const tagsInputRef = useRef("");

  const getUrlTitle = (str) => {
    if (str === "") return "";
    var d = Date.now().toString();
    var uri =
      str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+/g, "-")
        .replace(/^-+|-+$/g, "") +
      "-" +
      d.substring(d.length - 4, d.length);

    return checkIfURIExists(uri).then((exist) => {
      if (exist) {
        getUrlTitle(str);
      } else {
        return uri;
      }
    });
  };

  const handleChange = (event) => {
    setGenre(event.target.value);
  };
  const handleChangeAuthor = (event) => {
    setAuthor(event.target.value);
  };
  const handleDelete = (index) => {
    setTags((taglist) => taglist.filter((hashtag) => hashtag !== tags[index]));
  };
  const tagsKeyDownHandler = (e) => {
    const { key } = e;
    if (tags.length < 4 && key === "Enter") {
      var t = tagsInputRef.current.value;
      if (t !== "" && !tags.includes(t)) {
        setTags([...tags, t]);
        tagsInputRef.current.value = "";
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const checkIfURIExists = async (uri) => {
    const qry = query(blogCollectionRef, where("url_str", "==", uri));
    const data = await getDocs(qry);
    return data.docs.length !== 0;
  };

  const handleSubmit = (e) => {
    var hash = sha256.hmac.create(process.env.REACT_APP_SHA_KEY);

    if (
      genre === "" ||
      tinyImgUrl === "" ||
      bigImgUrl === "" ||
      title === "" ||
      markdownInput === "" ||
      mdFile === "" ||
      author === "" ||
      tags.length === 0
    ) {
      setOpen(true);
    } else {
      var pass = prompt("Please enter your password!");
      hash.update(pass);

      var p = authList.filter((item) => item.id === author)[0].pass;

      if (hash.hex() === p) {
        const genreRef = doc(collection(db, "genre"), genre);
        const authorRef = doc(collection(db, "author"), author);

        // body: markdownInput,
        const firebaseJSON = {
          isPublished: true,
          author: authorRef,
          big_img: bigImgUrl,
          genre: genreRef,
          posted: serverTimestamp(),
          readme_file : mdFile,
          read_time : getReadTime(markdownInput),
          tags: tags,
          tiny_img: tinyImgUrl,
          title: title,
          url_str: "",
        };

        const createBlog = async () => {
          await addDoc(blogCollectionRef, firebaseJSON);
          alert("Success!");
        };

        getUrlTitle(title).then((finalURI) => {
          firebaseJSON.url_str = finalURI;
          createBlog();
        });
      } else {
        alert("Failure!");
      }
    }
  };

  useEffect(() => {
    const getGenre = async () => {
      const qry = query(genreCollectionRef, orderBy("id", "asc"));
      const genreData = await getDocs(qry);
      setGenreList(
        genreData.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
      );
    };

    const getAuthor = async () => {
      const authData = await getDocs(authorCollectinRef);
      setAuthList(authData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getGenre();
    getAuthor();
  }, []);

  return (
    <div className="superRoot">
      <p className="mandatoryP">All * Marked Feilds are mandatory</p>
      <div className="root">
        <div className="input_area">
          <TextField
            required
            select
            id="outlined-select"
            value={genre}
            onChange={handleChange}
            label="Genre"
            helperText="Cannot Be Empty."
            variant="standard"
            sx={{ marginTop: 2 }}
          >
            {genreList.map((option) => (
              <MenuItem key={option.uid} value={option.uid}>
                {option.type}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            required
            id="standard-required"
            label="Blog Small Image URL"
            variant="standard"
            helperText="Cannot Be Empty."
            sx={{ marginTop: 2 }}
            onChange={(e) => setTinyImgUrl(e.target.value)}
          />

          <TextField
            required
            id="standard-required"
            label="Blog Header Image URL"
            variant="standard"
            helperText="Cannot Be Empty."
            sx={{ marginTop: 2 }}
            onChange={(e) => setBigImgUrl(e.target.value)}
          />

          <TextField
            required
            id="standard-required"
            label="Title "
            onChange={(e) => setTitle(e.target.value)}
            variant="standard"
            helperText={"Characters left : " + (2000 - title.length)}
            inputProps={{ maxLength: 2000 }}
            sx={{ marginTop: 2 }}
          />

          <TextField
            required
            id="standard-required"
            label="Tags (Press Enter to add the tag)"
            variant="standard"
            helperText="Minimum 1 Maximum 4"
            onKeyDown={tagsKeyDownHandler}
            sx={{ marginTop: 2 }}
            inputRef={tagsInputRef}
          />

          <Stack direction="row">
            {tags.map((tag, index) => (
              <Chip
                label={tag}
                variant="outlined"
                onDelete={(e) => handleDelete(index)}
              />
            ))}
          </Stack>

          <TextField
            required
            id="filled-multiline-static"
            label="Markdown File Name"
            value={mdFile}
            onChange={(e) => {
              setMdFile(e.target.value)
              if (e.target.value.includes(".md"))
                getGitHubFileContent(e.target.value).then((decodedContent) => {
                  setMarkdownInput(decodedContent);
                });
            }}
            variant="filled"
            helperText="Cannot Be Empty."
            sx={{ marginTop: 2 }}
          />

          <Typography>Custom Components</Typography>
          <Card sx={{ borderRadius: 1, padding: 2 }}>
            <Stack direction="row">
              <Typography>Ads - &lt;Ad&gt;&lt;/Ad&gt;</Typography>
            </Stack>
          </Card>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Author
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={author}
              onChange={handleChangeAuthor}
              label="Author"
            >
              {authList.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name + " (" + option.uid + ")"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <p>Read Time : {getReadTime(markdownInput)} mins</p>
        </div>



        <div className="output_area">
          <p>Small Image</p>
          <Stack direction="row" sx={{ marginBottom: 4 }}>
            <Card className="smallImgCard" sx={{ borderRadius: 0 }}>
              <img
                className="smallimg"
                src={tinyImgUrl}
                alt="Small Img Square"
              />
            </Card>
            <Card className="smallImgCard">
              <img
                className="smallimg"
                src={tinyImgUrl}
                alt="Small Img Square Rounded Corner"
              />
            </Card>
            <Card className="smallImgCard" sx={{ borderRadius: 200 }}>
              <img
                className="smallimg"
                src={tinyImgUrl}
                alt="Small Img Circle"
              />
            </Card>
          </Stack>
          <img src={bigImgUrl} alt="Big Img" />
          <h1 className="editorTitle">{title}</h1>
          <Stack direction="row">
            {tags.map((tag, index) => (
              <Chip label={tag} variant="outlined" sx={{ margin: "0 4px" }} />
            ))}
          </Stack>
          <MarkdownHandler className="outMarkDown" input={markdownInput} />
        </div>
      </div>
      <Button variant="contained" onClick={handleSubmit}>
        Submit
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Note archived"
      >
        <Alert severity="error">Please Fill all the Required(*) Fields!</Alert>
      </Snackbar>
    </div>
  );
}
