import { Avatar, Box, Button, Card } from "@mui/material";
import { Container } from "@mui/system";
import "./AuthDetails.css";
import { useNavigate } from "react-router-dom";
import ProfileFab from "../ProifleFAB/ProfileFab";

export default function AuthDetails({
  name,
  avatar,
  bio,
  location,
  profiles,
  uid,
  sx,
}) {
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate(`/portfolio`);
  };

  return (
    <Box sx={{ ...sx }}>
      <Box className="authorHolderDiv">
        <Container
          className="avatarBackground"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 74,
            height: 74,
            borderRadius: 200,
            background: "white",
            position: "absolute",
            marginTop: -4,
          }}
        >
          <Avatar
            className="avatar"
            sx={{ width: 64, height: 64 }}
            alt={name}
            src={avatar}
          />
        </Container>

        <Card
          className="authCard"
          sx={{
            background: "#d1fffb",
          }}
        >
          <Box>
            <p className="headingP">Bio:</p>
            <p className="bodyP">{bio}</p>
            <p className="headingP">Location:</p>
            <p className="bodyP">{location}</p>
            <p className="headingP">Profiles:</p>
            <Box className="linkIconContainer">
              <ProfileFab profiles={Object.values(profiles)} />
            </Box>
          </Box>
        </Card>
      </Box>

      <Box className="checkoutBtnContainer" sx={{ display: "flex" }}>
        <Button
          sx={{ width: { md: "100%", xs: "80%" }, textAlign: "center" }}
          variant="contained"
          className="checkoutBtn"
          onClick={navigateToProfile}
        >
          View Profile
        </Button>
      </Box>
    </Box>
  );
}
