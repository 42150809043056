import Box from "@mui/material/Box";
import React from "react";
import {
  authorCollectinRef,
  blogCollectionRef,
  indexCollectionRef,
} from "../../Components/firebase/firebase-config";
import { useEffect, useState } from "react";
import {
  getDocs,
  orderBy,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { convertToPlainText, getGitHubFileContent } from "../../Functions/Helper";
import { sha256 } from "js-sha256";
import { Button } from "@mui/material";

export default function IndexPage() {
  const [blogList, setBlogList] = useState([]);
  const [authorList, setAuthorList] = useState(new Map());
  const [isLoading, setIsLoading] = useState(true);

  const getAuthors = async () => {
    const data = await getDocs(authorCollectinRef);
    var out = new Map();
    data.docs.map((doc) => out.set(doc.id, { ...doc.data() }));
    return out;
  };

  const getBlogs = async () => {
    const blgQry = query(
      blogCollectionRef,
      where("isPublished", "==", true),
      orderBy("title")
    );
    const data = await getDocs(blgQry);
    var out = data.docs.map((doc) => ({ ...doc.data() }));
    return out;
  };

  useEffect(() => {
    getBlogs()
      .then((data) => {
        setBlogList(data);
      })
      .then(
        getAuthors().then((data) => {
          setAuthorList(data);
          setIsLoading(false);
        })
      );
  }, []);

  const convertToJson = async () => {
    const blogout = await Promise.all(
      blogList.map(async (blog, index) => {
        const body = await getGitHubFileContent(blog["readme_file"]);  // Wait for the async call
  
        const item = {
          body: convertToPlainText(body),  // Use the fetched and decoded content
          tags: blog["tags"],
          title: convertToPlainText(blog["title"]),
          author_uid: authorList.get(blog["author"]["id"])["uid"],
          author_name: authorList.get(blog["author"]["id"])["name"],
          url:
            window.location.origin +
            "/" +
            authorList.get(blog["author"]["id"])["uid"] +
            "/" +
            blog["url_str"],
        };
  
        return item;  // Return the item for this iteration
      })
    );
  
    return blogout;  // Return the complete array once all promises resolve
  };
  


  const handleSubmit = async() => {
    var hash = sha256.hmac.create(process.env.REACT_APP_SHA_KEY);

    var bJson =  JSON.stringify(await convertToJson());

    var pass = prompt("Please enter your password!");
    hash.update(pass);

    var p = authorList.get("CzflH2N4F0Cp6PrCkaji").pass;

    if (hash.hex() === p) {
      var indexref = doc(indexCollectionRef, "blog-index");

      const createBlog = async () => {
        await updateDoc(indexref, {
          index: bJson,
        });
        alert("Success!");
      };

      createBlog();
    } else {
      alert("Failure!");
    }
  };

  return (
    <Box>
      <p>OOPS Something went wrong</p>
      {isLoading ? (
        <Button variant="contained" onClick={handleSubmit} disabled>
          Generate
        </Button>
      ) : (
        <Button variant="contained" onClick={handleSubmit}>
          Generate
        </Button>
      )}
    </Box>
  );
}
