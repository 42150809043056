import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

export const blogCollectionRef = collection(db, "blogs");
export const authorCollectinRef = collection(db, "author");
export const genreCollectionRef = collection(db, "genre");
export const profileCollectionRef = collection(db, "profiles");
export const indexCollectionRef = collection(db, "index");

export function send_analitics_page_view(pageLocaction) {
  logEvent(analytics, "page_view", {
    page_location: pageLocaction,
  });
}
